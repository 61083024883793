import { Type } from '@angular/core';
import { LegacyQuotePagesPath } from '../../common/services/legacy/quote-pages';
import type { BaseQuoteForm } from './quote-form-base';
/**
 * **********************************************
 * QUOTE FORM DECORATOR
 * Decorator adds metadata to a QuoteForm components.
 * more on decorators: https://www.typescriptlang.org/docs/handbook/decorators.html#property-decorators
 * **********************************************
 */
interface QuoteFormContextConfig {
  legacyShape?: LegacyQuotePagesPath;
}
export function QuoteFormContextDecorator(config: QuoteFormContextConfig) {
  return (component: Type<BaseQuoteForm<unknown>>) => {
    // the value should match the static property on the BaseViewComponent
    Object.defineProperties(component, {
      formLegacyPath: {
        value: config.legacyShape,
        writable: false,
      },
    });
  };
}
