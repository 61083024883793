<app-form-card title="Do you want to add a co-applicant?" icon="lucideGoal">
  <p class="m-t-300 m-b-300">If there's another person's name on your home's deed or someone else with an insurable interest in the property, you must add them as a co-applicant.</p>
  <p>
    If the co-applicant is not a person (like a Trust, LLC, etc.) please
    <button (click)="contactDialogService.open()" class="button button--link" aria-label="Call Kin about your quote at 855-717-0022" data-testid="call-us-button">call us</button> to add them to your
    policy.
  </p>

  <form [formGroup]="coApplicantForm" (submit)="handleSubmit($event)" id="quote-form" class="flex w-full flex-col gap-400 self-start">
    <div class="mt-6 flex flex-row gap-100 sm:mt-10 sm:gap-400">
      <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="co-applicant-yes" [class.radio-card--invalid]="showError">
        <input class="radio-card__control peer outline-none" type="radio" id="co-applicant-yes" [value]="true" formControlName="hasCoApplicant" />
        <span for="co-applicant-yes">Yes</span>
      </label>
      <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="co-applicant-no" [class.radio-card--invalid]="showError">
        <input class="radio-card__control peer outline-none" type="radio" id="co-applicant-no" [value]="false" formControlName="hasCoApplicant" />
        <span for="co-applicant-no">No</span>
      </label>
    </div>

    <div aria-live="polite" aria-atomic="true">
      @if (showFormRequiredError()) {
        <div class="form-help form-help--invalid flex items-center gap-100">
          <ng-icon name="lucideCircleAlert" aria-hidden="true" class="!overflow-visible"></ng-icon>
          <span class="error-message">Please select a co-applicant option.</span>
        </div>
      }
    </div>
  </form>
</app-form-card>
