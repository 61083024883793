import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';
import type { QuoteDraftSelectorState } from '../../../../global-store/quote-draft';
import type { YearBuiltQuoteFormInput } from './year-built.types';

export function yearBuiltQuoteFormSelector(store: QuoteDraftSelectorState): YearBuiltQuoteFormInput {
  const yearBuilt = store.yearBuilt();
  const sqFt = store.sqFt();
  const stories = store.stories();
  const pool = store.pool();

  return {
    yearBuilt,
    sqFt,
    stories,
    pool,
  };
}

export function yearBuiltQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['year-built']();
}
