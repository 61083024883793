import { AbstractControl, ValidationErrors } from '@angular/forms';
import { parse, isValid, addYears, isBefore, isEqual, startOfDay } from 'date-fns';

/**
 * Validator function to check if the user meets the minimum age requirement.
 * @param control The form control containing the date of birth input.
 * @param getState A function that returns the user's state.
 * @returns A validation error object if validation fails, otherwise null.
 */
export function ageValidator(control: AbstractControl, getState: () => string | undefined): ValidationErrors | null {
  const inputValue = control.value;
  if (!inputValue) {
    return null;
  }

  if (inputValue.length < 10) {
    return null;
  }

  const dateOfBirth = parse(inputValue, 'MM/dd/yyyy', new Date());

  if (!isValid(dateOfBirth)) {
    return { invalidDateFormat: true };
  }

  const state = (getState() || '').toUpperCase();
  const minAge = state === 'AL' ? 19 : 18;

  const today = startOfDay(new Date());
  const dateOfBirthAtMidnight = startOfDay(dateOfBirth);
  const legalAgeDate = addYears(dateOfBirthAtMidnight, minAge);

  const hasReachedLegalAge = isBefore(legalAgeDate, today);
  const isExactlyLegalAge = isEqual(legalAgeDate, today);

  if (hasReachedLegalAge || isExactlyLegalAge) {
    return null;
  }

  return { underage: true, requiredAge: minAge };
}
