import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgKLoggerService } from '@kin/ngk-logger';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideGoal } from '@ng-icons/lucide';
import { LegacyPagesApiService } from 'app/common/services/legacy/quote-pages';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { BehaviorSubject, filter, first, take } from 'rxjs';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { FormCardComponent } from '../shared/form-card.component';
import type { PolicyGoalValue, PolicyGoalsQuoteFormInput } from './policy-goals.types';

@Component({
  selector: 'app-policy-goals',
  standalone: true,
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent],
  templateUrl: './policy-goals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideIcons({ lucideGoal, lucideCircleAlert })],
  styles: [
    `
      :host {
        grid-area: content;
      }
    `,
  ],
})
export class QuoteFormPolicyGoalsComponent extends BaseQuoteForm<PolicyGoalsQuoteFormInput> implements OnInit {
  public formDataIn: PolicyGoalsQuoteFormInput = this.quoteDraftStore.policyGoalsQuoteFormSelector();

  public policyGoalOptions = [
    { label: 'Lower price', value: 'lower_price', id: 'lower_price' },
    { label: 'Better protection', value: 'better_protection', id: 'better_protection' },
    { label: 'I care equally about price and protection', value: 'both', id: 'both' },
  ];

  public formDefinition = {
    policyGoal: new FormControl<PolicyGoalValue | undefined>(undefined, Validators.required),
  };
  public policyGoalsForm = new FormGroup(this.formDefinition);

  public isResuming = false;

  private router = inject(Router);
  private pagesService = inject(LegacyPagesApiService);
  public localPagePath: string | undefined;
  private optionsStore = inject(QuoteFormOptionsStore);
  private logger = inject(NgKLoggerService);
  private activatedRoute = inject(ActivatedRoute);
  private destroyRef = inject(DestroyRef);

  private localPagePathSubject = new BehaviorSubject<string | undefined>(undefined);
  private localPagePath$ = this.localPagePathSubject.asObservable().pipe(
    filter((path): path is string => path !== undefined),
    take(1),
  );

  public ngOnInit() {
    // Check for the isResuming query parameter; if present and true, then the user is resuming their quote
    // and won't have a value for `policyGoal` in the `quoteDraftStore`. The `policyGoal` is necessary to
    // make API calls to the `PackagesController` in dot-com before making API calls to the `PoliciesController`,
    // so the user must select a value before proceeding to the Review Page. In this case, this page does *not*
    // need to make API calls to the `PagesController`, but instead directly navigates to the Review Page after
    // saving the `policyGoal` to the `quoteDraftStore`.
    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      this.isResuming = params['isResuming'] === 'true';
      if (this.isResuming) {
        return;
      }

      if (this.formDataIn.policyGoal) {
        this.policyGoalsForm.controls.policyGoal.setValue(this.formDataIn.policyGoal);
      }

      /**
       * This component is an edge case in that it is manually injected right after the About You page and
       * taken out of the normal flow.
       * On init, it will make a request to the Pages API to find the current next page.
       * When the user submits, it's possible the request is still pending so we wrap it in an observable
       * to ensure we don't navigate until the request is complete.
       */
      this.pagesService
        .pollForNextPage()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (resp) => {
            if (!resp.page) {
              this.logger.error({ message: `Pages API response did not include a page`, context: 'QuoteFormPolicyGoalsComponent', priority: 'P3' });
              return;
            }

            const formOptions = this.optionsStore.getLegacyOptionsForForm(resp.page.path, resp.page.components, this.logger);
            this.optionsStore.updateOptionsForFormByLegacyPath(resp.page.path, formOptions, this.logger);
            this.localPagePathSubject.next(resp.page.path);
          },
          error: (resp: HttpErrorResponse) => {
            const msg = resp.message && resp.message !== '' ? resp.message : 'Unexpected Error';
            this.logger.error({ message: `Error getting next page from Pages API: ${msg}`, context: 'QuoteFormPolicyGoalsComponent', priority: 'P3' });
          },
        });
    });
  }

  private getFormValues(): Pick<QuoteDraftState, 'policyGoal'> {
    const { controls } = this.policyGoalsForm;
    const formValues = {
      policyGoal: controls.policyGoal.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.policyGoalsForm.markAllAsTouched();
    if (this.policyGoalsForm.valid) {
      this.quoteDraftStore.patchStore(this.getFormValues());
      this.quoteDraftStore.updateStatus('loading');

      if (this.isResuming) {
        this.quoteDraftStore.updateStatus('success');
        this.router.navigate(['/quote/review']);
        return;
      }

      this.localPagePath$.subscribe((path) => {
        this.quoteDraftStore.updateStatus('success');
        this.router.navigate([`quote/legacy/`, path]);
      });
    }
  }

  public get showError(): boolean {
    const control = this.policyGoalsForm.get('policyGoal');
    return !!control?.invalid && !!control?.touched;
  }
}
