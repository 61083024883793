import { AppEnvironment } from '../app-config.model';

/**
 * Used to configure the Fullstory Org ID for each environment.
 * You can find the Org ID in the Kin's Fullstory account's URL: https://app.fullstory.com/ui/YVGQ5/home.
 * Also can be found explicity in the code snippets on the settings page: https://app.fullstory.com/ui/YVGQ5/settings/general.
 * At the time of writing this, the Org ID is the same for every environment.
 */
export const fullstoryEnvConfig: ReadonlyMap<AppEnvironment, string> = new Map([
  ['local', 'YVGQ5'],
  ['development', 'YVGQ5'],
  ['staging', 'YVGQ5'],
  ['production', 'YVGQ5'],
]);
