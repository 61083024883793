import { NgKLoggerService } from '@kin/ngk-logger';
import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';
import { ConstructionTypeConfig, ConstructionTypeRadioButtonOption, LegacyApiPagesConstructionTypeComponent } from './construction-type.types';

const LEGACY_PAGES_API_CONSTRUCTION_TYPE_OPTIONS_VALUES = ['frame', 'masonry', 'log', 'superior_fire_resistive'];

const constructionTypeConfig: ConstructionTypeConfig = {
  frame: {
    label: 'Frame',
    description: 'Built with lumber, but the exterior wall surface may be covered with wood, brick, decorative veneers or stucco.',
    image: 'frame.png',
    imageAlt: 'Interior wooden framing of a building under construction',
  },
  masonry: {
    label: 'Masonry',
    description: 'Consists of brick and stone structures put together with mortar.',
    image: 'masonry.png',
    imageAlt: 'Partially constructed concrete block wall',
  },
  log: {
    label: 'Log',
    description: 'Consists of stacked logs where the same wood is seen from the inside and the outside.',
    image: 'log.png',
    imageAlt: 'Construction site of a log cabin in winter',
  },
  superior_fire_resistive: {
    label: 'Superior fire-resistive',
    description: 'Made of materials that can withstand high heat and flame, such as concrete or cinder blocks.',
    image: 'superior_fire_resistive.png',
    imageAlt: 'Unfinished modern concrete house with a flat roof',
  },
};

export function getOptionsForLegacyPagesApiConstructionTypeComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): ConstructionTypeRadioButtonOption[] {
  const constructionTypeComponent = components.find((component) => component.attribute_name === 'construction_type');
  if (!constructionTypeComponent) {
    logger.error({ message: 'could not find component for construction_type', context: 'getOptionsForLegacyPagesApiConstructionTypeComponent', priority: 'P3' });
    return [];
  }

  const options = (constructionTypeComponent as LegacyApiPagesConstructionTypeComponent).select_options.map((option) => {
    return {
      label: constructionTypeConfig[option.value]?.label || option.label,
      value: option.value,
      id: option.value.toString(),
      description: constructionTypeConfig[option.value]?.description,
      image: constructionTypeConfig[option.value]?.image || 'frame.png', // Default to frame image
      imageAlt: constructionTypeConfig[option.value]?.imageAlt,
    };
  });

  if (options.some((option) => !LEGACY_PAGES_API_CONSTRUCTION_TYPE_OPTIONS_VALUES.includes(option.value))) {
    logger.error({ message: 'unexpected value found in dot-com select_options for construction_type component', context: 'getOptionsForLegacyPagesApiConstructionTypeComponent', priority: 'P3' });
    return [];
  }

  return options;
}
