import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';
import type { QuoteDraftSelectorState } from '../../../global-store/quote-draft';
import type { RentalNumberQuoteFormInput } from './rental-number.types';

export function rentalNumberQuoteFormSelector(store: QuoteDraftSelectorState): RentalNumberQuoteFormInput {
  const rentalNumber = store.rentalNumber();
  return { rentalNumber };
}

export function rentalNumberQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['rental-number']();
}
