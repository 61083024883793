import { QuoteFormOptionsState } from './quote-form-options.model';

export const quoteFormOptionsInitialState: QuoteFormOptionsState = {
  'about-you': undefined,
  'policy-type': [],
  'unit-number-check': undefined,
  'unit-number-entry': undefined,
  'insurance-status': [],
  'rental-status': undefined,
  'rental-number': [],
  residency: [],
  'policy-start-date': undefined,
  'co-applicant': undefined,
  'co-applicant-info': undefined,
  'property-type': [],
  'roof-shape': [],
  'roof-year': undefined,
  'year-built': [],
  'construction-type': [],
  'exterior-wall': [],
  'property-information': [],
  foundation: [],
};
