import { AppContextInterface } from '../app-config.model';
import { AppConfigModule } from '../app-config.module';
import { AppConfigService } from '../app-config.service';

/**
 * Context property decorator
 * - used for easy access to application level context
 *   information (this should not change once app is booted up)
 * - more on decorators: https://www.typescriptlang.org/docs/handbook/decorators.html#property-decorators
 */
export function AppContext() {
  const importWarning = 'AppConfigModule not found. You must import it to use this decorator.';

  // The App Config Module exposes the angular injector so we can access the app context
  const get = () => {
    try {
      return AppConfigModule.injector.get(AppConfigService).appContext;
    } catch (error) {
      // fixes ts warning: https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;
      throw new Error(`${importWarning} \nCause: ${message}`);
    }
  };

  return (target: object, key: string | symbol) => {
    // adds a getter and setter to the decorated target object
    Object.defineProperty(target, key, {
      configurable: false,
      enumerable: true,
      get,
    });
  };
}

/**
 * Leverage declaration merging to avoid needing to import two things when the decorator is used
 */
/* eslint-disable-next-line @typescript-eslint/no-redeclare */
export type AppContext = AppContextInterface;
