import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideGoal, lucideCircleAlert } from '@ng-icons/lucide';
import { CoApplicantQuoteFormInput } from './co-applicant.types';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { FormCardComponent } from '../shared/form-card.component';
import { ContactDialogService } from '../../services/contact-dialog/contact-dialog.service';

@Component({
  selector: 'app-co-applicant',
  standalone: true,
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent],
  providers: [provideIcons({ lucideGoal, lucideCircleAlert })],
  templateUrl: './co-applicant.component.html',
  styleUrl: './co-applicant.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        grid-area: content;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/co-applicant',
})
export class QuoteFormCoApplicantComponent extends BaseQuoteForm<CoApplicantQuoteFormInput> implements OnInit {
  protected contactDialogService = inject(ContactDialogService);

  public formDataIn: CoApplicantQuoteFormInput = this.quoteDraftStore.hasCoApplicantQuoteFormSelector();

  public formDefinition = {
    hasCoApplicant: new FormControl<boolean | undefined>(undefined, Validators.required),
  };
  public coApplicantForm = new FormGroup(this.formDefinition);

  public get showError(): boolean {
    const control = this.coApplicantForm.get('hasCoApplicant');
    return !!control?.invalid && !!control?.touched;
  }

  public ngOnInit() {
    if (this.formDataIn) {
      this.coApplicantForm.controls.hasCoApplicant.setValue(this.formDataIn.hasCoApplicant);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'hasCoApplicant'> {
    const { controls } = this.coApplicantForm;
    const formValues = {
      hasCoApplicant: controls.hasCoApplicant.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.coApplicantForm.markAllAsTouched();
    if (this.coApplicantForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }

  public showFormRequiredError(): boolean {
    const control = this.coApplicantForm.get('hasCoApplicant');
    return control ? control.invalid && control.touched : false;
  }
}
