import { inject } from '@angular/core';
import { signalStore, withState } from '@ngrx/signals';
import { withStorageSync } from '@angular-architects/ngrx-toolkit'; // docs: https://github.com/angular-architects/ngrx-toolkit
import { SESSION_STORAGE } from '@ng-web-apis/common';
import { withQuoteDraftSelectors } from './selectors/quote-draft.selectors';
import { quoteDraftInitialState } from './quote-draft-initial-state';
import { withQuoteDraftMethods } from './effects/quote-draft.methods';

/**
 * Quote Draft Store
 * - this is provided in the orchestration component
 * Great Intro Post: https://offering.solutions/blog/articles/2023/12/03/ngrx-signal-store-getting-started/
 */

export const QuoteDraftStore = signalStore(
  { providedIn: 'root' },
  withState(quoteDraftInitialState),
  // Selectors
  withQuoteDraftSelectors(),
  // Methods
  withQuoteDraftMethods(),
  withStorageSync({
    key: 'quote',
    autoSync: true,
    storage: () => inject(SESSION_STORAGE),
  }),
);
