import { NgKLoggerService } from '@kin/ngk-logger';
import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';
import { ExteriorWallConfig, ExteriorWallRadioButtonOption, LegacyApiPagesExteriorWallComponent } from './exterior-wall.types';

const LEGACY_PAGES_API_EXTERIOR_WALL_OPTIONS_VALUES = [
  'brick_solid',
  'traditional_stucco',
  'siding_vinyl',
  'siding_cement_fiber_clapboard',
  'wood_shingle_shake',
  'stone_veneer_natural',
  'metal_corrugated_galvanized',
];

const exteriorWallConfig: ExteriorWallConfig = {
  brick_solid: {
    label: 'Brick',
    description: 'The exterior is made of solid bricks or brick veneer.',
    image: 'brick.png',
  },
  traditional_stucco: {
    label: 'Stucco',
    description: 'This is a type of cement made to look like plaster.',
    image: 'stucco.png',
  },
  siding_vinyl: {
    label: 'Vinyl',
    description: 'This is a durable form of plastic siding.',
    image: 'vinyl.png',
  },
  siding_cement_fiber_clapboard: {
    label: 'Cement Fiber',
    description: 'Siding made from fiber cement (mix of wood pulp and cement), which is also known as hardie board.',
    image: 'cement.png',
  },
  wood_shingle_shake: {
    label: 'Wood',
    description: 'Usually made from wood shakes or shingles, plywood, or pine clapboard.',
    image: 'wood.png',
  },
  stone_veneer_natural: {
    label: 'Stone',
    description: "The home's exterior is made of solid stones or stone veneer.",
    image: 'stone.png',
  },
  metal_corrugated_galvanized: {
    label: 'Metal',
    description: 'Typically made of aluminum siding, but can also be steel.',
    image: 'metal.png',
  },
};

export function getOptionsForLegacyPagesApiExteriorWallComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): ExteriorWallRadioButtonOption[] {
  const exteriorWallComponent = components.find((component) => component.attribute_name === 'exterior_wall_finish');
  if (!exteriorWallComponent) {
    logger.error({ message: 'could not find component for exterior_wall_finish', context: 'getOptionsForLegacyPagesApiExteriorWallComponent', priority: 'P3' });
    return [];
  }

  const options = (exteriorWallComponent as LegacyApiPagesExteriorWallComponent).select_options.map((option) => {
    return {
      label: exteriorWallConfig[option.value]?.label || option.label,
      value: option.value,
      id: option.value.toString(),
      description: exteriorWallConfig[option.value]?.description,
      image: exteriorWallConfig[option.value]?.image || 'brick.png', // Default to brick image
    };
  });

  if (options.some((option) => !LEGACY_PAGES_API_EXTERIOR_WALL_OPTIONS_VALUES.includes(option.value))) {
    logger.error({ message: 'unexpected value found in dot-com select_options for exterior_wall_finish component', context: 'getOptionsForLegacyPagesApiExteriorWallComponent', priority: 'P3' });
    return [];
  }

  return options;
}
