import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContactDialogService {
  private _isOpen = signal(false);
  /**
   * Whether the contact dialog is open or not
   */
  isOpen = this._isOpen.asReadonly();

  open() {
    this._isOpen.set(true);
  }

  close() {
    this._isOpen.set(false);
  }

  toggle() {
    this._isOpen.update((value) => !value);
  }
}
