import { interval, switchMap, timeout, take, filter, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';
import { AppContext } from '../../../../app-config';
import { LegacyApiPagesResponse } from '../quote-pages/models';

/**
 * Legacy Pages Polling API Service
 *
 * @desc: v1 api pages GET call used to understand the status of the POST pages API call
 *
 * @example use:
 * return this.httpClient.post(URL, PAYLOAD).pipe(switchMap(() => this.pollingService.poll()));
 */
@Injectable({
  providedIn: 'root',
})
export class LegacyPagesPollingApiService {
  @AppContext() private _appContext!: AppContext;
  private httpClient = inject(HttpClient);
  private logger = inject(NgKLoggerService);

  /**
   * Gets url based on app context
   */
  private get url() {
    return `${this._appContext.apiRoot.quoteV1}/pages`;
  }

  /**
   * Polls the specified URL at regular intervals until a successful response is received or a timeout occurs.
   *
   * @returns {Observable<LegacyApiPagesResponse>} - An observable that emits the successful response or an error if the polling times out.
   *
   * @memberof QuotePagesPollingService
   */
  public poll() {
    const pollingInterval = 1000; // in milliseconds
    const maxPollingTime = 70000; // in milliseconds

    return interval(pollingInterval).pipe(
      // Since the httpClient returns an Observable, wrap it with a switchMap
      switchMap(() => this.httpClient.get<LegacyApiPagesResponse>(this.url)),

      // Filter only the successful http responses
      filter((data) => !this.shouldKeepPolling(data)),

      // Emit only the first value emitted by the source
      take(1),

      // Time out after 30 seconds
      timeout({
        each: maxPollingTime,
        with: () => {
          this.logger.error({ message: 'timeout', context: `LegacyPagesPollingApiService`, priority: 'P3' });
          return throwError(() => new Error('timeout'));
        },
      }),
    );
  }

  /**
   * Determines whether the polling should continue based on the response.
   *
   * @param {LegacyApiPagesResponse | null} response - The response from the API.
   * @returns {boolean} - Returns `true` if polling should continue, `false` otherwise.
   */
  private shouldKeepPolling(response: LegacyApiPagesResponse | null): boolean {
    // Implement your logic to check if the response is the desired one
    if (response?.redirect_to) {
      return false;
    }

    return response === null || response.page?.layout === 'loading';
  }
}
