import { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';
import type { QuoteDraftSelectorState } from '../../../global-store/quote-draft';
import { AboutYouQuoteFormInput } from './about-you.types';
import { utilToUiDate } from '../../../common/utils/iso-date-type/to-iso-date';

export function aboutYouQuoteFormSelector(store: QuoteDraftSelectorState): AboutYouQuoteFormInput {
  const { applicant, hasOptedIntoSms } = store;
  const dob = applicant()?.dob;
  const formattedDob = dob ? utilToUiDate(dob) : '';
  const email = applicant()?.email ?? '';
  const firstName = applicant()?.firstName ?? '';
  const lastName = applicant()?.lastName ?? '';
  const phoneNumber = applicant()?.phoneNumber ?? '';

  return {
    dob: formattedDob ?? '',
    email,
    firstName,
    lastName,
    phoneNumber,
    hasOptedIntoSms: hasOptedIntoSms() ?? false,
  };
}

export function aboutYouQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['about-you']();
}
