import { Type } from '@angular/core';
import { QuoteFormAboutYouComponent } from '../forms/about-you/about-you.component';
import { QuoteFormCoApplicantInfoComponent } from '../forms/co-applicant-info/co-applicant-info.component';
import { QuoteFormCoApplicantComponent } from '../forms/co-applicant/co-applicant.component';
import { QuoteFormInsuranceExpirationComponent } from '../forms/insurance-expiration/insurance-expiration.component';
import { QuoteFormInsuranceStatusComponent } from '../forms/insurance-status/insurance-status.component';
import { QuoteFormPolicyGoalsComponent } from '../forms/policy-goals/policy-goals.component';
import { QuoteFormPolicyStartDateComponent } from '../forms/policy-start-date/policy-start-date.component';
import { QuoteFormPolicyTypeComponent } from '../forms/policy-type/policy-type.component';
import { QuoteFormConstructionTypeComponent } from '../forms/property-details/construction-type/construction-type.component';
import { QuoteFormExteriorWallComponent } from '../forms/property-details/exterior-wall/exterior-wall.component';
import { QuoteFormPropertyTypeComponent } from '../forms/property-details/property-type/property-type.component';
import { QuoteFormRoofShapeComponent } from '../forms/property-details/roof-shape/roof-shape.component';
import { QuoteFormRoofYearComponent } from '../forms/property-details/roof-year/roof-year.component';
import { QuoteFormYearBuiltComponent } from '../forms/property-details/year-built/year-built.component';
import { QuoteFormRentalNumberComponent } from '../forms/rental-number/rental-number.component';
import { QuoteFormRentalStatusComponent } from '../forms/rental-status/rental-status.component';
import { QuoteFormResidencyComponent } from '../forms/residency/residency.component';
import { QuoteFormUnitNumberCheckComponent } from '../forms/unit-number-check/unit-number-check.component';
import { QuoteFormUnitNumberEntryComponent } from '../forms/unit-number-entry/unit-number-entry.component';
import type { BaseQuoteForm } from './quote-form-base';
import { QuoteFormFoundationTypeComponent } from '../forms/property-details/foundation/foundation.component';

import { QuoteFormPropertyInformationComponent } from '../forms/property-information/property-information.component';
/**
 *
 * COMPONENT MAPPINGS
 * Maps the path provided by the v1 API to a quote form
 * Paths are defined here: https://github.com/kin/dot-com/blob/master/app/models/acquisition/page/path.rb
 */
const quoteFormMappingArray: [string, Type<BaseQuoteForm<unknown>>][] = [
  ['about-you', QuoteFormAboutYouComponent],
  ['policy-type', QuoteFormPolicyTypeComponent],
  ['unit-number-check', QuoteFormUnitNumberCheckComponent],
  ['unit-number-entry', QuoteFormUnitNumberEntryComponent],
  ['insurance-status', QuoteFormInsuranceStatusComponent],
  ['rental-status', QuoteFormRentalStatusComponent],
  ['rental-number', QuoteFormRentalNumberComponent],
  ['residency', QuoteFormResidencyComponent],
  ['policy-start-date', QuoteFormPolicyStartDateComponent],
  ['co-applicant', QuoteFormCoApplicantComponent],
  ['co-applicant-info', QuoteFormCoApplicantInfoComponent],
  ['property-type', QuoteFormPropertyTypeComponent],
  ['roof-shape', QuoteFormRoofShapeComponent],
  ['insurance-expiration', QuoteFormInsuranceExpirationComponent],
  ['policy-goals', QuoteFormPolicyGoalsComponent],
  ['year-built', QuoteFormYearBuiltComponent],
  ['construction-type', QuoteFormConstructionTypeComponent],
  ['roof-year', QuoteFormRoofYearComponent],
  ['exterior-wall', QuoteFormExteriorWallComponent],
  ['property-information', QuoteFormPropertyInformationComponent],
  ['foundation', QuoteFormFoundationTypeComponent],
] as const;

export type QuoteFormMappingKey = (typeof quoteFormMappingArray)[number][0];

export const quoteFormMappingMap = new Map<QuoteFormMappingKey, Type<BaseQuoteForm<unknown>>>(quoteFormMappingArray);
