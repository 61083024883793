import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgKLoggerService } from '@kin/ngk-logger';
import { SESSION_STORAGE, WINDOW } from '@ng-web-apis/common';
import { tapResponse } from '@ngrx/operators';
import { patchState, WritableStateSource } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { of, pipe } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { LegacyQuotePagesPath } from '../../../../common/services/legacy/quote-pages/models/paths.config';
import { LegacyPagesApiService } from '../../../../common/services/legacy/quote-pages/quote-pages.service';
import { mapLegacyPathToLegacyPayload } from '../../../../create-quote/form-config/map-legacy-path-to-legacy-payload';
import type { StoreStatus } from '../../../store-status.model';
import type { QuoteDraftSelectorState, QuoteDraftState } from '../../quote-draft.model';

/**
 * Save Form Action / Effect
 * Use to save a QuoteForm to the Quote Draft store and send to Pages API
 *
 * @todo we should extend this to include another to support a non-legacy path if it isn't defined as opposed to throwing an error
 */

export type SaveFormAction = {
  formData: Partial<QuoteDraftState>;
  legacyPath?: LegacyQuotePagesPath;
};
export function saveForm(store: WritableStateSource<QuoteDraftState>, pagesApiService: LegacyPagesApiService, router: Router, logger: NgKLoggerService) {
  const optionsStore = inject(QuoteFormOptionsStore);
  const window = inject(WINDOW);
  const sessionStorage = inject(SESSION_STORAGE);

  return rxMethod<SaveFormAction>(
    pipe(
      distinctUntilChanged(),
      tap(() => {
        // turn on the loader
        return patchState(store, { storeStatus: 'loading' });
      }),
      switchMap((saveFormAction) => {
        // save form data to the store
        patchState(store, saveFormAction.formData);
        if (saveFormAction.legacyPath) {
          // get the correct payload
          const { legacyPath } = saveFormAction;

          const payload = mapLegacyPathToLegacyPayload(store as unknown as QuoteDraftSelectorState, legacyPath);

          if (payload) {
            // call API to create the customer input response id
            return pagesApiService.save(payload, saveFormAction.legacyPath).pipe(
              tapResponse({
                next: (res) => {
                  const errorStatus = { code: 500, msg: 'Unexpected Error' };
                  // `redirect_to` takes user out of "questions" flow and to either:
                  // - Terminal page, e.g. underwriting failure page.
                  // - Compare Page then Review Page.
                  // - Rails pages to finish the quote.
                  // - Resume Page. The "/resume" page `redirect_to` link is returned as a full URL.
                  if (res.redirect_to) {
                    if (res.redirect_to.includes('http')) {
                      window.location.href = res.redirect_to;
                      return patchState(store, { storeStatus: 'success' });
                    }
                    router.navigate([`${res.redirect_to}`]);
                    return patchState(store, { storeStatus: 'success' });
                  }

                  if (!res.page) {
                    logger.error({ message: `Pages API response did not include a page`, context: 'saveForm Effect/Action::pagesApiService', priority: 'P3' });
                    return patchState(store, { storeStatus: errorStatus });
                  }

                  if (res.page.user_exists === true) {
                    sessionStorage.setItem('user_exists', 'true');
                  }

                  const formOptions = optionsStore.getLegacyOptionsForForm(res.page.path, res.page.components, logger);
                  optionsStore.updateOptionsForFormByLegacyPath(res.page.path, formOptions, logger);

                  // If we're coming from the "About You" form, hard code redirect to the "Policy Goals" form
                  if (saveFormAction.legacyPath === '/quote/about-you') {
                    router.navigate(['quote/policy-goals']);
                  } else {
                    router.navigate(['quote/legacy', res.page.path]);
                  }

                  return patchState(store, { storeStatus: 'success' });
                },
                error: (res: HttpErrorResponse) => {
                  const msg = res.message && res.message !== '' ? res.message : 'Unexpected Error';
                  const errorStatus: StoreStatus = { code: res.status ?? 500, msg };
                  logger.error({ message: `Pages API failure ${msg}`, context: 'saveForm Effect/Action::pagesApiService', priority: 'P3' });
                  return patchState(store, { storeStatus: errorStatus });
                },
              }),
            );
          }
        } else {
          logger.error({ message: `unable to generate pages api request payload from legacyPath: ${saveFormAction.legacyPath}`, context: 'saveForm Effect/Action', priority: 'P3' });
        }
        const errorStatus: StoreStatus = { code: 0, msg: 'Unexpected Error' };
        return of(patchState(store, { storeStatus: errorStatus }));
      }),
    ),
  );
}
