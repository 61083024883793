import { AppEnvironment } from '../app-config.model';

const appEnvConfig: ReadonlyMap<string, AppEnvironment> = new Map([
  ['*.dev.kin.co', 'development'],
  ['*.kin.haus', 'staging'],
  ['*.kin.com', 'production'],
  ['*.kin.insure', 'production'],
]);

/**
 * Function used to return the correct app environment for the passed clientHost
 */
export function getAppEnvironment(clientHost: string): AppEnvironment | undefined {
  // Check if the clientHost is localhost with any port
  if (/^localhost(:\d+)?$/.test(clientHost)) {
    return 'local';
  }

  const entry = Array.from(appEnvConfig.entries()).find(([hostPattern]) => {
    // replace host pattern "." with an escaped dot "\."
    // replace host pattern "*" with a regex wild card ".*"
    // ensures any wild cards added will match
    const regexPattern = new RegExp(`^${hostPattern.replace(/\\/g, '\\\\').replace(/\./g, '\\.').replace(/\*/g, '.*')}$`);
    return regexPattern.test(clientHost);
  });

  if (entry) {
    const [, environment] = entry;
    return environment;
  }
  return undefined;
}
