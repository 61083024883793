<div class="md:[grid-area:content]">
  <form [formGroup]="policyGoalsForm" (ngSubmit)="handleSubmit($event)" id="quote-form" class="col-span-4 flex flex-col gap-400 self-start md:col-span-2 md:col-start-2 lg:col-span-2 lg:col-start-2">
    <app-form-card [title]="'What is most important to you for your insurance policy?'" subcopy="Your answer will help us build a quote that meets your needs." icon="lucideGoal">
      <div class="grid grid-cols-1">
        <fieldset class="form-group__fieldset">
          <legend class="sr-only">Select your policy goal</legend>
          <div class="grid gap-300 min-[1270px]:grid-cols-3">
            @for (policyGoalOption of policyGoalOptions; track policyGoalOption) {
              <label
                class="radio-card group w-full focus-within:ring-2 focus-within:ring-primary"
                [class.radio-card--invalid]="showError"
                [for]="policyGoalOption.id"
                [attr.data-testid]="'policy-goal-' + policyGoalOption.id"
              >
                <span class="group-has-[:checked]:font-bold">
                  {{ policyGoalOption.label }}
                </span>
                <input class="radio-card__control peer outline-none" type="radio" [id]="policyGoalOption.id" [value]="policyGoalOption.value" formControlName="policyGoal" />
              </label>
            }
          </div>
        </fieldset>
        <div id="policy-goals-message" class="form-help mt-0" aria-live="polite" aria-atomic="true">
          @if (showError) {
            <div class="form-help--invalid mt-300 flex items-center gap-100" id="policy-goals-error">
              <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
              Please make a selection
            </div>
          }
        </div>
      </div>
    </app-form-card>
  </form>
</div>
