import { NgKLoggerService } from '@kin/ngk-logger';
import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';
import { LegacyApiPagesRoofYearComponent, RoofYearTypeValidations } from './roof-year.types';

export function getValidationsForLegacyPagesAPIRoofYearComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): RoofYearTypeValidations | undefined {
  const roofYearComponent = components.find((component) => component.attribute_name === 'roof_construction_year');
  if (!roofYearComponent) {
    logger.error({ message: 'could not find component for roof_year', context: 'getMinValueForLegacyPagesAPIRoofYearComponent', priority: 'P3' });
    return undefined;
  }

  const { validations } = roofYearComponent as LegacyApiPagesRoofYearComponent;

  if (!validations) {
    logger.error({ message: 'unexpected value found in dot-com validations for roof_construction_year', context: 'getMinValueForLegacyPagesAPIRoofYearComponent', priority: 'P3' });
    return undefined;
  }

  return validations;
}
