import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';
import type { QuoteDraftSelectorState } from '../../../../global-store/quote-draft';
import type { ConstructionTypeQuoteFormInput } from './construction-type.types';

export function constructionTypeQuoteFormSelector(store: QuoteDraftSelectorState): ConstructionTypeQuoteFormInput {
  const constructionType = store.constructionType();
  return { constructionType };
}

export function constructionTypeQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['construction-type']();
}
