import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons, provideNgIconLoader } from '@ng-icons/core';
import { lucideCircleAlert, lucideGoal } from '@ng-icons/lucide';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { FormCardComponent } from '../shared/form-card.component';
import { isAddressInHD3 } from './policy-type-utils';
import { PolicyTypeQuoteFormInput, PolicyTypeValue } from './policy-type.types';

@Component({
  selector: 'app-policy-type',
  standalone: true,
  imports: [FormCardComponent, NgIconComponent, ReactiveFormsModule],
  templateUrl: './policy-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    provideIcons({ lucideCircleAlert, lucideGoal }),
    provideNgIconLoader((name) => {
      const http = inject(HttpClient);
      return http.get(`/assets/icons/${name}.svg`, { responseType: 'text' });
    }),
  ],
  styles: [
    `
      :host {
        grid-area: content;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/policy_type',
})
export class QuoteFormPolicyTypeComponent extends BaseQuoteForm<PolicyTypeQuoteFormInput> implements OnInit {
  private optionsStore = inject(QuoteFormOptionsStore);
  public formDataIn: PolicyTypeQuoteFormInput = this.quoteDraftStore.policyTypeQuoteFormSelector();

  public policyTypeOptions = this.optionsStore.policyTypeQuoteFormOptionsSelector();

  public formDefinition = {
    policyType: new FormControl<PolicyTypeValue | undefined>(undefined, Validators.required),
  };
  public policyTypeForm = new FormGroup(this.formDefinition);

  private addressState = this.quoteDraftStore.address()?.state ?? '';

  public ngOnInit() {
    if (this.formDataIn.policyType) {
      this.policyTypeForm.controls.policyType.setValue(this.formDataIn.policyType);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'policyType'> {
    const { controls } = this.policyTypeForm;
    const formValues = {
      policyType: controls.policyType.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.policyTypeForm.markAllAsTouched();
    if (this.policyTypeForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }

  public get showError(): boolean {
    const control = this.policyTypeForm.get('policyType');
    return !!control?.invalid && !!control?.touched;
  }

  public get isHD3State(): boolean {
    return isAddressInHD3(this.addressState);
  }
}
