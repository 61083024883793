import { inject } from '@angular/core';
import type { LegacyQuotePagesPath } from '../../common/services/legacy/quote-pages';
import { QuoteFormSaveService } from '../services/save';
import type { QuoteDraftState } from '../../global-store/quote-draft/quote-draft.model';
import { QuoteDraftStore } from '../../global-store/quote-draft';

/**
 * **********************************************
 * BASE QUOTE FORM
 * Represents the base class for all quote forms.
 * @template FormData - The type of form data needed for the quote form.
 * **********************************************
 */
export abstract class BaseQuoteForm<QuoteFormInput> {
  // Injected Services
  readonly quoteDraftStore = inject(QuoteDraftStore);
  readonly saveService = inject(QuoteFormSaveService);

  // tells the service what the shape of the data is we want to save
  static formLegacyPath?: LegacyQuotePagesPath;

  // form data in
  abstract formDataIn?: QuoteFormInput;

  // method that saves the form data to the save service, and then the store.
  public saveFormData(formData: Partial<QuoteDraftState>) {
    // pull in the form config details - references the constructor to ensure we get the instance value, as opposed to the abstract class value
    const formShape = (this.constructor as typeof BaseQuoteForm).formLegacyPath;
    // save form data to the service
    this.saveService.saveFormData(formData, formShape);
  }
}
