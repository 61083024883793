import { Injector, NgModule } from '@angular/core';

/**
 * Kin Angular application config module
 * provides configuration context to the apps components and services
 */
@NgModule({})
export class AppConfigModule {
  // set an injector property to work with Angular's dependency injection
  public static injector: Injector;

  // expose angular's dependency injection so we can access it in the decorator
  constructor(injector: Injector) {
    AppConfigModule.injector = injector;
  }
}
