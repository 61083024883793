// Could move this into a more configurable area.∏

export const VALIDATION_CONSTANTS = {
  REGEX: {
    /**
     * "email" regex  validates email addresses by ensuring:
     * * <local-part>@<domain-part>.<top-level-domain>
     * * The local part consists of one or more lowercase letters, digits, or specified special characters.
     * * The local part can have dot-separated sections.
     * * The domain part consists of one or more sections of lowercase letters or digits, optionally separated by hyphens, followed by a dot.
     * * The top-level domain consists of lowercase letters or digits, optionally separated by hyphens.
     * * The i flag makes the pattern case-insensitive, allowing it to match uppercase letters as well.
     */
    email: /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
    /**
     * "emailDomain" regex captures the domain part of an email address, including all characters after "@".
     * e.g. domainString = emailString.match(emailDomain))
     */
    emailDomain: /([^@]*$)/g,
    /**
     * "phone" regex validates US phone numbers with the following rules:
     * * The area code must be a 3-digit number starting with a digit from 2 to 9.
     * * The central office code must be a 3-digit number starting with a digit from 2 to 9 and cannot be 11.
     * * The line number must be a 4-digit number.
     * * The phone number can optionally include parentheses around the area code and can use dashes, dots, or spaces as separators.
     */
    phone: '^\\(?([2-9][0-9]{2})\\)?[-. ]?([2-9](?!11)[0-9]{2})[-. ]?([0-9]{4})$',
    /**
     * "date" regex requires the format 'yyyy-mm-dd', where mm is beteween 01-12 and dd is between 01-31
     * This format is the direct output of the native date input element in HTML5
     */
    date: '^\\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$',
  },
};

/**
 * These validation messages were ported from ng-kin are currently unused in this app.
 */
export const VALIDATION_MESSAGES = {
  required: 'This field is required.',
  maxlength: 'This field has too many characters.',
  minlength: 'This field has not enough characters.',
  dobInvalid: 'The acceptable age range is from 18 to 122.',
  dateInvalid: 'The input given is not a valid date.',
  emailInvalid: 'This is not a valid email address.',
  minValue: 'This field is too low.',
  maxValue: 'This field is too high.',
  minLength: 'This field has not enough characters.',
  maxLength: 'This field has too many characters.',
  minAgeDays: 'This field is out of the acceptable range.',
  maxAgeDays: 'This field is out of the acceptable range.',
  minAgeYears: 'This field is out of the acceptable range.',
  maxAgeYears: 'This field is out of the acceptable range.',
  phoneInvalid: 'This phone number is not valid.',
  creditCardNumberInvalid: 'The credit card number is not valid.',
  creditCardNameInvalid: "The cardholder's name is not valid.",
  creditCardDateInvalid: 'The expiration date is not valid.',
  creditCardCVVInvalid: 'The CVV is not valid',
  creditCardZipInvalid: 'The billing zip code is not valid',
};
