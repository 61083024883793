<form [formGroup]="rentalStatusForm" (submit)="handleSubmit($event)" id="quote-form" class="col-span-4 flex w-full flex-col gap-400 self-start sm:col-span-2 sm:col-start-2">
  <app-form-card title="Do you plan to rent out your property in the next 12 months?" icon="lucideHouse">
    <div class="mt-6 flex flex-row gap-100 sm:mt-10 sm:gap-400">
      <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="rental-status-yes" [class.radio-card--invalid]="showError">
        <input class="radio-card__control peer outline-none" type="radio" id="rental-status-yes" [value]="true" formControlName="rentalStatus" />
        <span for="rental-status-yes">Yes</span>
      </label>
      <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="rental-status-no" [class.radio-card--invalid]="showError">
        <input class="radio-card__control peer outline-none" type="radio" id="rental-status-no" [value]="false" formControlName="rentalStatus" />
        <span for="rental-status-no">No</span>
      </label>
    </div>

    <div aria-live="polite" aria-atomic="true">
      @if (showFormRequiredError()) {
        <div class="form-help form-help--invalid flex items-center gap-100">
          <ng-icon name="lucideCircleAlert" aria-hidden="true" class="!overflow-visible"></ng-icon>
          <span class="error-message">Please select a rental option.</span>
        </div>
      }
    </div>
  </app-form-card>
</form>
