import { NgKLoggerService } from '@kin/ngk-logger';
import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';
import { LegacyApiPagesRentalNumberComponent, RentalNumberSelectOption } from './rental-number.types';

export function getOptionsForLegacyPagesApiRentalNumberComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): RentalNumberSelectOption[] {
  const rentalNumberComponent = components.find((component) => component.attribute_name === 'times_rented');

  if (!rentalNumberComponent) {
    logger.error({ message: 'could not find component for rental-number `times_rented`', context: 'getOptionsForLegacyPagesApiRentalNumberComponent', priority: 'P3' });
    return [];
  }

  const options = (rentalNumberComponent as unknown as LegacyApiPagesRentalNumberComponent).select_options.map((option) => {
    return {
      label: option.label,
      value: option.value,
    };
  });
  return options;
}
