import { LegacyQuotePagesPath } from '../../common/services/legacy/quote-pages';
import type { BaseQuoteForm } from './quote-form-base';
import { QuoteFormMappingKey, quoteFormMappingMap } from './quote-form.config';

/**
 * **********************************************
 * MAP LEGACY PATH TO FORM KEY
 * Searches for a matching LegacyPath in the quoteFormMapping.
 * the legacy path is set on the forms via the QuoteFormContextDecorator
 * **********************************************
 */
export function mapLegacyPathToFormKey(legacyPath: LegacyQuotePagesPath): QuoteFormMappingKey | undefined {
  if (!legacyPath) {
    return undefined;
  }
  const entry = Array.from(quoteFormMappingMap.entries()).find(([, formConfig]) => {
    const formClass = formConfig as typeof BaseQuoteForm;
    return formClass.formLegacyPath === legacyPath;
  });
  return entry ? entry[0] : undefined;
}
