import { patchState, signalStoreFeature, type, withMethods } from '@ngrx/signals';
import { NgKLoggerService } from '@kin/ngk-logger';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { QuoteDraftState } from '../quote-draft.model';
import { LegacyPagesApiService } from '../../../common/services/legacy/quote-pages/quote-pages.service';
import { StoreStatus } from '../../store-status.model';
import { saveForm } from './save-form/save-form.effect';
import { quoteDraftInitialState } from '../quote-draft-initial-state';

/**
 * Quote Draft Store Methods
 * https://ngrx.io/guide/signals/signal-store#defining-store-methods
 *
 * Methods are how you add data to the store acting as actions, effects and reducers
 */
export function withQuoteDraftMethods() {
  return signalStoreFeature(
    { state: type<QuoteDraftState>() },
    withMethods((store, pagesService = inject(LegacyPagesApiService), router = inject(Router), loggerService = inject(NgKLoggerService)) => ({
      // Careful calling this method, you can update anything on the store
      patchStore(newState: Partial<QuoteDraftState>): void {
        patchState(store, () => newState);
      },
      updateStatus(status: StoreStatus): void {
        patchState(store, () => ({ storeStatus: status }));
      },
      reset(): void {
        patchState(store, () => quoteDraftInitialState);
      },
      saveFormData: saveForm(store, pagesService, router, loggerService),
    })),
  );
}
