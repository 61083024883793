import { NgKLoggerService } from '@kin/ngk-logger';
import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';
import { LegacyApiPagesCoApplicantComponent } from './co-applicant.types';

const LEGACY_PAGES_API_COAPPLICANT_OPTIONS_VALUES = [false, null];

export function getOptionsForLegacyPagesApiCoApplicantComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): undefined {
  const coApplicantComponent = components.find((component) => component.attribute_name === 'include_coapplicant');
  if (!coApplicantComponent) {
    logger.error({ message: 'could not find component for include_coapplicant', context: 'getOptionsForLegacyPagesApiCoApplicantComponent', priority: 'P3' });
    return;
  }

  const optionsValues = (coApplicantComponent as unknown as LegacyApiPagesCoApplicantComponent).select_options.map((option) => {
    return option.value;
  });

  if (optionsValues.some((option) => !LEGACY_PAGES_API_COAPPLICANT_OPTIONS_VALUES.includes(option))) {
    logger.error({ message: 'unexpected value found in dot-com select_options for include_coapplicant component', context: 'getOptionsForLegacyPagesApiCoApplicantComponent', priority: 'P3' });
  }
}
