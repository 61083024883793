import { NgKLoggerService } from '@kin/ngk-logger';
import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';
import { LegacyApiPagesPropertyTypeComponent, PropertyTypeConfig, PropertyTypeRadioButtonOption } from './property-type.types';

const LEGACY_PAGES_API_PROPERTY_TYPE_OPTIONS_VALUES = ['single_family_detached', 'townhouse', 'manufactured_mobile_home', 'condominium', 'commercial', 'multi_family_unknown_num_of_units', 'land'];

const propertyTypeConfig: PropertyTypeConfig = {
  single_family_detached: {
    label: 'Single-family detached',
    description: 'A home, built on a single lot with no shared walls.',
    icon: 'home',
  },
  townhouse: {
    label: 'Townhouse',
    description: 'Single units in a small community with multiple owners, often having multiple floors and at least one shared wall between homes.',
    icon: 'townhouse',
  },
  manufactured_mobile_home: {
    label: 'Manufactured/mobile home',
    description: 'Factory-built homes that are moved to a location and usually kept there permanently.',
    icon: 'manufactured_home',
  },
  condominium: {
    label: 'Condo',
    description: 'Single units with multiple owners within a larger building or community, usually part of an association that also shares a wall (or more than one) with at least one other unit.',
    icon: 'condo',
  },
  commercial: {
    label: 'Commercial',
    description: 'Real estate used for business activities, including large residential rental properties.',
    icon: 'commercial',
  },
  multi_family_unknown_num_of_units: {
    label: 'Multi-family',
    description: 'Single building with one owner, designed to house multiple families living separately.',
    icon: 'multi_family',
  },
  land: {
    label: 'Land',
    description: 'A lot or land with no built structure to insure.',
    icon: 'land',
  },
};

export function getOptionsForLegacyPagesApiPropertyTypeComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): PropertyTypeRadioButtonOption[] {
  const propertyTypeComponent = components.find((component) => component.attribute_name === 'property_type');
  if (!propertyTypeComponent) {
    logger.error({ message: 'could not find component for property_type', context: 'getOptionsForLegacyPagesApiPropertyTypeComponent', priority: 'P3' });
    return [];
  }

  const options = (propertyTypeComponent as LegacyApiPagesPropertyTypeComponent).select_options.map((option) => {
    return {
      label: propertyTypeConfig[option.value]?.label || option.label,
      value: option.value,
      id: option.value.toString(),
      description: propertyTypeConfig[option.value]?.description,
      icon: propertyTypeConfig[option.value]?.icon || 'home', // Default to home icon
    };
  });

  if (options.some((option) => !LEGACY_PAGES_API_PROPERTY_TYPE_OPTIONS_VALUES.includes(option.value))) {
    logger.error({ message: 'unexpected value found in dot-com select_options for property_type component', context: 'getOptionsForLegacyPagesApiPropertyTypeComponent', priority: 'P3' });
    return [];
  }

  return options;
}
