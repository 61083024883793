<form [formGroup]="residencyForm" (submit)="handleSubmit($event)" id="quote-form" class="flex w-full flex-col gap-400 self-start [grid-area:content]">
  <app-form-card title="Do you live on this property for most of the year?" icon="lucideHouse" class="">
    <fieldset class="form-group__fieldset mt-400">
      <legend class="sr-only">Select your residency status</legend>
      <div class="grid gap-200 md:grid-cols-3">
        @for (residencyOption of residencyOptions; track residencyOption) {
          <label
            class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary"
            [class.radio-card--invalid]="showFormRequiredError()"
            [for]="residencyOption.id"
            [attr.data-testid]="'residency-' + residencyOption.id"
          >
            <div class="radio-card__header flex flex-col gap-100">
              <span class="radio-card__title w-full font-medium leading-200 group-has-[:checked]:font-bold">{{ residencyOption.label }} </span>
            </div>
            <input class="radio-card__control peer outline-none" type="radio" [id]="residencyOption.id" [value]="residencyOption.value" formControlName="residency" />
          </label>
        }
      </div>
    </fieldset>

    <div aria-live="polite" aria-atomic="true">
      @if (showFormRequiredError()) {
        <div class="form-help form-help--invalid error-message flex items-center gap-100">
          <ng-icon name="lucideCircleAlert" class="" aria-hidden="true"></ng-icon>
          Please select a residency option.
        </div>
      }
    </div>
  </app-form-card>
</form>
<app-form-card class="self-start [grid-area:end-rail]">
  <div class="flex flex-col gap-100">
    <h2 class="flex flex-col gap-300 text-100 font-bold leading-[1.375]">
      <svg xmlns="http://www.w3.org/2000/svg" fill="var(--color-primary)" viewBox="0 0 32 32" class="h-[2em] w-[2em]">
        <path
          fill="var(--color-primary)"
          stroke="var(--color-neutral-800)"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10.534 26.666a12 12 0 1 0-5.2-5.2l-2.667 7.867 7.867-2.667Z"
        />
        <path fill="var(--color-primary)" d="M12.12 12a4 4 0 0 1 7.773 1.333c0 2.667-4 4-4 4" />
        <path stroke="var(--color-neutral-800)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12.12 12a4 4 0 0 1 7.773 1.333c0 2.667-4 4-4 4" />
        <path fill="var(--color-primary)" d="M16 22.667h.013H16Z" />
        <path stroke="var(--color-neutral-800)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 22.667h.013" />
      </svg>
      Why are we asking for this?
    </h2>
    <p>We'll use your property info plus additional data from our trusted sources to build you the most accurate quote.</p>
  </div>
</app-form-card>
