import { NgKLoggerService } from '@kin/ngk-logger';
import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';

const LEGACY_PAGES_API_ABOUT_YOU_COMPONENT_ATTRIBUTE_NAMES = ['first_name', 'last_name', 'phone_number', 'dob', 'email', 'opt_in_text', 'consent'];

export function getComponentsForLegacyPagesApiAboutYou(components: LegacyApiPagesComponent[], logger: NgKLoggerService): undefined {
  const aboutYouComponents = components.filter((component) => LEGACY_PAGES_API_ABOUT_YOU_COMPONENT_ATTRIBUTE_NAMES.includes(component.attribute_name));
  if (aboutYouComponents.length === 0 || aboutYouComponents.length !== LEGACY_PAGES_API_ABOUT_YOU_COMPONENT_ATTRIBUTE_NAMES.length) {
    logger.error({ message: 'could not find components for about-you page', context: 'getComponentsForLegacyPagesApiAboutYou', priority: 'P3' });
  }
}
