import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideHouse } from '@ng-icons/lucide';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { FormCardComponent } from '../shared/form-card.component';
import { UnitNumberCheckQuoteFormInput } from './unit-number-check.types';

@Component({
  selector: 'app-unit-number-check',
  standalone: true,
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent],
  templateUrl: './unit-number-check.component.html',
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/unit_number_check',
})
export class QuoteFormUnitNumberCheckComponent extends BaseQuoteForm<UnitNumberCheckQuoteFormInput> implements OnInit {
  public formDataIn: UnitNumberCheckQuoteFormInput = this.quoteDraftStore.unitNumberCheckQuoteFormSelector();

  public formDefinition = {
    hasUnitNumber: new FormControl<boolean | undefined>(undefined, Validators.required),
  };
  public unitNumberCheckForm = new FormGroup(this.formDefinition);

  public get showFormRequiredError(): boolean {
    const control = this.unitNumberCheckForm.get('hasUnitNumber');
    return !!control?.invalid && !!control?.touched;
  }

  public ngOnInit() {
    if (this.formDataIn) {
      this.unitNumberCheckForm.controls.hasUnitNumber.setValue(this.formDataIn.hasUnitNumber);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'hasUnitNumber'> {
    const { controls } = this.unitNumberCheckForm;
    const formValues = {
      hasUnitNumber: controls.hasUnitNumber.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.unitNumberCheckForm.markAllAsTouched();
    if (this.unitNumberCheckForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }
}
