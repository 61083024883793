/**
 * Used to configure the smarty streets key for each environment
 * Keys are defined and assigned in the smarty streets console, accessible with 1password credentials
 */

import { AppEnvironment } from '../app-config.model';

export const smartyStreetsEnvConfig: ReadonlyMap<AppEnvironment, string> = new Map([
  ['local', '17390637764554244'],
  ['development', '17390635667331796'],
  ['staging', '17390635034904124'],
  ['production', '17390636601685852'],
]);
