import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';
import { NgKLoggerService } from '@kin/ngk-logger';
import { YearBuiltFormOption } from './year-built.types';

const EXPECTED_ATTRIBUTE_NAMES = ['pool', 'stories', 'sq_ft', 'year_built'] as const;
type ExpectedAttributeName = (typeof EXPECTED_ATTRIBUTE_NAMES)[number];

export function getOptionsForLegacyPagesApiYearBuiltComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): YearBuiltFormOption[] {
  return components
    .filter(isExpectedComponent)
    .map(componentToFormOption)
    .filter((option): option is YearBuiltFormOption => option !== null);

  function isExpectedComponent(component: LegacyApiPagesComponent): component is LegacyApiPagesComponent & { attribute_name: ExpectedAttributeName } {
    if (EXPECTED_ATTRIBUTE_NAMES.includes(component.attribute_name as ExpectedAttributeName)) {
      return true;
    }
    logger.error({
      message: `Unknown attribute_name returned from legacy api: ${component.attribute_name}`,
      context: 'getOptionsForLegacyPagesApiYearBuiltComponent',
      priority: 'P3',
    });
    return false;
  }

  function componentToFormOption(component: LegacyApiPagesComponent & { attribute_name: ExpectedAttributeName }): YearBuiltFormOption | null {
    try {
      return {
        id: component.attribute_name,
        label: component.label,
      };
    } catch (error) {
      logger.error({
        message: `Error processing component: ${error instanceof Error ? error.message : 'Unknown error'}`,
        context: 'getOptionsForLegacyPagesApiYearBuiltComponent',
        priority: 'P3',
      });
      return null;
    }
  }
}
