import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';
import type { QuoteDraftSelectorState } from '../../../../global-store/quote-draft';
import type { ExteriorWallQuoteFormInput } from './exterior-wall.types';

export function exteriorWallQuoteFormSelector(store: QuoteDraftSelectorState): ExteriorWallQuoteFormInput {
  const exteriorWall = store.exteriorWall();
  return { exteriorWall };
}

export function exteriorWallQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['exterior-wall']();
}
