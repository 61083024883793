import { NgKLoggerService } from '@kin/ngk-logger';
import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';
import { LegacyApiPagesRoofShapeComponent, RoofShapeRadioButtonOption } from './roof-shape.types';
import type { RoofShapeConfig } from './roof-shape.types';

const roofShapeConfig: RoofShapeConfig = {
  hip: {
    description: 'Has slopes on all 4 sides that come together at the top to form a ridge.',
  },
  flat: {
    description: 'Has nearly no pitch and allows for extra use of space, like a rooftop garden.',
  },
  other: {
    description: "Choose this option if your roof's shape is not listed here.",
  },
};

export function getOptionsForLegacyPagesApiRoofShapeComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): RoofShapeRadioButtonOption[] {
  const roofShapeComponent = components.find((component) => component.attribute_name === 'roof_shape');
  if (!roofShapeComponent) {
    logger.error({ message: 'could not find component for roof_shape', context: 'getOptionsForLegacyPagesApiRoofShapeComponent', priority: 'P3' });
    return [];
  }

  const options = (roofShapeComponent as LegacyApiPagesRoofShapeComponent).select_options.map((option) => {
    return {
      label: option.label,
      value: option.value,
      id: option.value,
      description: roofShapeConfig[option.value]?.description || '',
    };
  });

  return options;
}
