import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';
import type { QuoteDraftSelectorState } from '../../../../global-store/quote-draft';
import type { PropertyTypeQuoteFormInput } from './property-type.types';

export function propertyTypeQuoteFormSelector(store: QuoteDraftSelectorState): PropertyTypeQuoteFormInput {
  const propertyType = store.propertyType();
  return { propertyType };
}

export function propertyTypeQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['property-type']();
}
