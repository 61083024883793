<div class="flex flex-col gap-400 self-start">
  <form [formGroup]="policyTypeForm" (ngSubmit)="handleSubmit($event)" id="quote-form">
    <app-form-card [title]="'What type of insurance are you looking for?'" icon="lucideGoal">
      <div class="mt-600 grid grid-cols-1 gap-x-400 gap-y-400 md:gap-y-600">
        <fieldset class="form-group__fieldset">
          <legend class="sr-only">Select your policy type</legend>
          <div class="grid gap-200 md:grid-cols-2">
            @for (policyTypeOption of policyTypeOptions; track policyTypeOption) {
              <label
                class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary"
                [class.radio-card--invalid]="showError"
                [for]="policyTypeOption.id"
                [attr.data-testid]="'policy-type-' + policyTypeOption.id"
              >
                <div class="radio-card__header flex flex-col gap-100">
                  <div class="flex w-full flex-wrap items-center justify-start gap-100">
                    <ng-icon [name]="policyTypeOption.icon" size="30" class="radio-card__icon !overflow-visible text-black"></ng-icon>
                    <p class="radio-card__title font-medium group-has-[:checked]:font-bold">{{ policyTypeOption.label }}</p>
                  </div>
                  <div class="w-full">
                    <p class="text-sm">{{ policyTypeOption.description }}</p>
                  </div>
                </div>
                <input class="radio-card__control peer outline-none" type="radio" [id]="policyTypeOption.id" [value]="policyTypeOption.id" formControlName="policyType" />
              </label>
            }
          </div>
        </fieldset>
        @if (showError) {
          <div class="fs-body-md fw-medium error-message flex items-center gap-100 text-warning" role="alert" data-testid="form-error-message">
            <ng-icon name="lucideCircleAlert" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
            Please make a selection
          </div>
        }
      </div>
    </app-form-card>
  </form>

  @if (isHD3State) {
    <app-form-card>
      <p class="text-50 font-normal leading-[1.3] text-neutral-800" data-testid="hd3-disclaimer">
        For properties located outside of Florida or Louisiana, Kin "homeowners insurance" and "home insurance" refers to the
        <a href="https://www.kin.com/home-insurance/house-and-property-insurance/" target="_blank" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary"
          >Kin House & Property policy</a
        >
        with the Owner-Occupied Endorsement added. The base Kin House & Property policy, the
        <a href="https://www.kin.com/home-insurance/house-and-property-insurance/" target="_blank" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary">HD3 policy</a>,
        provides the coverages for customers who rent out their home to others, similar to a
        <a href="https://www.kin.com/blog/dp3-policy/" target="_blank" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary">DP3 policy</a>. For customers who live in their
        home full time or part time, the Owner-Occupied Endorsement is added to provide the coverages of a typical homeowners insurance policy, which is similar to a
        <a href="https://www.kin.com/glossary/ho3-policy/" target="_blank" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary">HO3 policy</a>.
      </p>
    </app-form-card>
  }
</div>
